import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function usePlayersList() {
  // Use toast
  const toast = useToast()

  const refPlayerListTable = ref(null)
  // Table Handlers
  const tableColumns = [
    // {
    //   key: 'id',
    //   label: '#',
    //   sortable: true,
    //   thStyle: {
    //     display: 'none',
    //   },
    // },
    // {
    //   key: 'phone_number',
    //   label: 'Staff Phone',
    //   sortable: true,
    //   thStyle: {
    //     display: 'none',
    //   },
    // },
    // {
    //   key: 'msisdn',
    //   sortable: true,
    // },
    // {
    //   key: 'msisdn',
    //   thStyle: {
    //     display: 'none',
    //   },
    // },
    {
      key: 'name',
      formatter: title,
    },
    // {
    //   key: 'mno',
    // },
    {
      key: 'cash',
      sortable: true,
      label: "Balance"
    },
    {
      key: 'bonus',
      label: "Bonus"
    },
    // {
    //   key: 'platform',
    // },
    {
      key: 'is_active',
    },
    {
      key: 'is_verified',
    },
    {
      key: 'is_payout_locked',
      label: 'Payout Status'
    },
    {
      key: 'created_at',
      sortable: true,
      label: 'Joined On'
    },
    {
      key: 'last_login',
      sortable: true,
      label: 'Last Login'
    },
    {
      key: 'bets',
      label: '#Bets'
      },
    {
      key: 'actions',
    },
  ]
  const perPage = ref(10)
  const totalPlayers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const isBusy = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refPlayerListTable.value ? refPlayerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPlayers.value,
    }
  })

  const refetchData = () => {
    refPlayerListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchPlayers = (ctx, callback) => {
    isBusy.value = true
    store
      .dispatch('players/fetchPlayers', {
        search: searchQuery.value,
        page_size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, paginator } = response.data

        callback(data)
        totalPlayers.value = paginator.length
        isBusy.value = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching players' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePlayerStatusVariantAndIcon = status => {
    if (status === false) return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === true) return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Inactive') return { variant: 'danger', icon: 'XIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }
  const resolvePlayerAvatarVariant = status => {
    if (status === true) return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Inactive') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }
  const resolvePayoutStatusVariantAndIcon = status => {
    if (status === false) return { variant: 'success', icon: 'UnlockIcon' }
    if (status === true) return { variant: 'warning', icon: 'LockIcon' }
    if (status === 'Inactive') return { variant: 'danger', icon: 'XIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }
  return {
    fetchPlayers,
    tableColumns,
    perPage,
    currentPage,
    totalPlayers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlayerListTable,
    isBusy,

    statusFilter,

    resolvePlayerStatusVariantAndIcon,
    resolvePlayerAvatarVariant,
    resolvePayoutStatusVariantAndIcon,
    refetchData,
  }
}
