<template>
<div>
  <b-card
  no-body
  class="mb-50"
  >
  <div class="m-1">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>

    </div>
  </b-card>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <b-table
      ref="refPlayerListTable"
      :items="fetchPlayers"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy.sync="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
    <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
          label="Loading..."
          class="align-middle">
          </b-spinner>
          <strong class=" ml-2">Fetching...</strong>
        </div>
      </template>
      <!-- Column: Player -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.name)"
              :variant="`light-${resolvePlayerAvatarVariant(data.item.is_active)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </span>
          <small class="text-muted">{{ data.item.msisdn }}</small>
        </b-media>
      </template>
      <!-- Column: Balance -->
      <template #cell(is_verified)="data">
        <template v-if="data.item.is_verified == true">
          <b-badge
            pill
            variant="light-success"
          >
            {{ data.item.is_verified==true ? 'Yes': 'Not' }}
          </b-badge>
        </template>
        <template v-else>
          <b-badge
            pill
            variant="light-secondary"
          >
            {{ data.item.is_verified==true ? 'Yes': 'No' }}
          </b-badge>
        </template>
      </template>
      <!-- Column: Balance -->
      <template #cell(is_payout_locked)="data">
        <b-avatar
          :id="`player-row-payout${data.item.id}`"
          size="32"
          :variant="`light-${resolvePayoutStatusVariantAndIcon(data.item.is_payout_locked).variant}`"
        >
          <feather-icon
            :icon="resolvePayoutStatusVariantAndIcon(data.item.is_payout_locked).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`player-row-payout${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            Status: {{ data.item.is_payout_locked == true ? 'Locked' : 'Unlocked' }}
          </p>
        </b-tooltip>
      </template>
      <!-- Column: Player Status -->
      <template #cell(is_active)="data">
        <b-avatar
          :id="`player-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolvePlayerStatusVariantAndIcon(data.item.is_active).variant}`"
        >
          <feather-icon
            :icon="resolvePlayerStatusVariantAndIcon(data.item.is_active).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`player-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            Status: {{ data.item.is_active }}
          </p>
          <p class="mb-0">
            Type: {{ data.item.is_active }}
          </p>
          <p class="mb-0">
            Created at: {{ data.item.created_at }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(created_at)="data">
        <span class="text-nowrap">
          {{ data.item.created_at }}
        </span>
      </template>
      <!-- Column: Issued Date -->
      <template #cell(platform)="data">
        <span class="text-nowrap">
          {{ data.item.platform }}
        </span>
      </template>
      <!-- Column: Issued Date -->
      <template #cell(mno)="data">
        <span class="text-nowrap">
          {{ data.item.mno }}
        </span>
      </template>
      <!-- Column: Issued Date -->
      <template #cell(last_login)="data">
        <span class="text-nowrap">
          {{ data.item.last_login }}
        </span>
      </template>
      <!-- Column: Issued Date -->
      <template #cell(bets)="data">
        <span class="text-nowrap">
          {{ data.item.bets }}
        </span>
      </template>
      <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
    
            <b-dropdown-item
              v-if="userData.access_level!='Staff'"
              @click="onUserChangeStatus(data.item.is_active, data.item.id, data.item.name, data.item.msisdn)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ data.item.is_active==true ? 'Suspend': 'Activate' }}</span>
            </b-dropdown-item>
            <b-dropdown-item
                v-if="userData.access_level!='Staff'"
                @click="onPayoutChange(data.item.is_payout_locked, data.item.id, data.item.name, data.item.msisdn)">
              <feather-icon
                :icon="data.item.is_payout_locked==true ? 'UnlockIcon': 'LockIcon'">
              </feather-icon>
              <span class="align-middle ml-50">{{ data.item.is_payout_locked==true ? 'Unlock Payouts': 'Lock Payouts' }}</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-players-view', params: { id: data.item.id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalPlayers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar,
  BBadge, BPagination, BTooltip, BDropdown, BDropdownItem, BButton, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import usePlayersList from './usePlayersList'
import axios from '@axios'
import playerStoreModule from '../playerStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,
    vSelect,
  },
  setup({}, ctx) {
    const PLAYERS_APP_STORE_MODULE_NAME = 'players'

    // Register module
    if (!store.hasModule(PLAYERS_APP_STORE_MODULE_NAME)) store.registerModule(PLAYERS_APP_STORE_MODULE_NAME, playerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLAYERS_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAYERS_APP_STORE_MODULE_NAME)
    })
    const userData = ref(JSON.parse(localStorage.getItem('userData')))
    const isChangeUserConfirmed = ref(false)
    const isLockPayoutConfirmed = ref(false)
    const changeUserResponse = ref(null)
    const msg = ref('')
    const okVar = ref('')
    const onUserChangeStatus = (isActive,userId,PlayerName,PlayerMsisdn) => {
      if(isActive==false){
        msg.value = 'Activate'
        okVar.value = 'success'
      }
      else{
        msg.value = "Suspend"
        okVar.value = 'danger'
      }
      ctx.root.$bvModal.msgBoxConfirm(`Please confirm that you want to ${msg.value} ${PlayerName}.`, {
          title: `${ msg.value } ${ PlayerName } - ${ PlayerMsisdn }  `,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: okVar.value,
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            isChangeUserConfirmed.value = value
            if(isChangeUserConfirmed.value==true){
              const submitData = {
                is_active: !isActive
              }
              axios.patch(`/users/${userId}/`, submitData)
                .then(response => {
                  changeUserResponse.value = JSON.parse(JSON.stringify(response.data.data))
                  refetchData()
                })
            }
          })
          .catch(err => {
            // An error occurred
          })
      }

      const onPayoutChange = (isLocked,userId,PlayerName,PlayerMsisdn) => {
      if(isLocked==false){
        msg.value = 'Lock Payouts Account'
        okVar.value = 'success'
      }
      else{
        msg.value = "Unlock Payouts Account"
        okVar.value = 'danger'
      }
      ctx.root.$bvModal.msgBoxConfirm(`Please confirm that you want to ${msg.value} for ${PlayerName}.`, {
          title: `${ msg.value } ${ PlayerName } - ${ PlayerMsisdn }  `,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: okVar.value,
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            isLockPayoutConfirmed.value = value
            if(isLockPayoutConfirmed.value==true){
              const submitData = {
                is_payout_locked: !isLocked
              }
              axios.patch(`/users/${userId}/`, submitData)
                .then(response => {
                  changeUserResponse.value = JSON.parse(JSON.stringify(response.data.data))
                  refetchData()
                })
            }
          })
          .catch(err => {
            // An error occurred
          })
      }
    const {
      fetchPlayers,
      tableColumns,
      perPage,
      currentPage,
      totalPlayers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlayerListTable,

      statusFilter,
      isBusy,

      refetchData,

      resolvePlayerStatusVariantAndIcon,
      resolvePlayerAvatarVariant,
      resolvePayoutStatusVariantAndIcon,
    } = usePlayersList()

    return {
      fetchPlayers,
      tableColumns,
      perPage,
      currentPage,
      totalPlayers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlayerListTable,
      isBusy,

      statusFilter,
      refetchData,
      avatarText,
      resolvePlayerStatusVariantAndIcon,
      resolvePlayerAvatarVariant,
      resolvePayoutStatusVariantAndIcon,
      onUserChangeStatus,
      onPayoutChange,
      userData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.player-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
